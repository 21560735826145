import {
  about01,
  about02,
  banner01,
  banner02,
  banner03,
  banner04,
  bg02,
  icon01,
  icon02,
  icon03,
  icon52,
  icon53,
  icon54,
  icon55,
  icon56,
  icon57,
  portfolio01,
  portfolio02,
  portfolio03,
  service01,
  service02,
  service03,
  service04,
} from "../../media/Images";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Header from "../../inc/Header";
import Footer from "../../inc/Footer";
import { Link, useNavigate } from "react-router-dom";

export const Home = ({ component: Component }) => {
  let navigate = useNavigate();
  useEffect(() => {
    // null
    // return () => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
      mirror: false,
    });
    // };
  }, []);

  return (
    <>
      {/* <div id="preloader"></div> */}

      <div className="main-wrapper">
        {/* HEADER */}
        <Header children={null} />
        {/* HEADER */}
        {/* BANNER
        ================================================== */}
        <div className="top-position1 z-index-1">
          <div className="slider-fade banner1">
            {/* OWL CAROUSEL */}
            <div className="owl-carousel owl-theme w-100">

              <div
                className="text-center item bg-img primary-overlay"
                data-overlay-dark="8"
                data-background={banner04}
                style={{ backgroundImage: `url(${banner04})` }}
              >
                <div className="container h-100 w-100">
                  <div className="d-table h-100 w-100">
                    <div className="d-table-cell align-middle caption">
                      <div className="overflow-hidden w-95 w-md-85 w-lg-80 mt-md-5 mt-lg-5">
                        <h1 className="text-warning w-lg-90 w-xl-80 w-xxl-70 mx-auto pt-md-5 pt-lg-5">
                          Bienvenue à
                          <span className="stroke">
                            {" "}
                            l'Institut Saint Paul
                          </span>
                        </h1>

                        <p className="w-90 w-xl-60 mx-auto d-md-block">
                          L'Institut Saint Paul est une œuvre diocésaine fondée en 1988...
                        </p>

                        <div className="py-4 mt-n1-9">
                          <Link
                            to="/about"
                            className="butn white me-4 mt-1-9 d-sm-inline-block"
                          >
                            <i className="ti-arrow-right icon-arrow before white"></i>
                            <span className="label">En savoir plus</span>
                            <i className="ti-arrow-right icon-arrow after"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="text-center item bg-img primary-overlay"
                data-overlay-dark="8"
                data-background={banner01}
                style={{ backgroundImage: `url(${banner01})` }}
              >
                <div className="container h-100 w-100">
                  <div className="d-table h-100 w-100">
                    <div className="d-table-cell align-middle caption">
                      <div className="overflow-hidden w-95 w-md-85 w-lg-80 mt-md-5 mt-lg-5">
                        <h1 className="text-warning w-lg-90 w-xl-80 w-xxl-70 mx-auto pt-md-5 pt-lg-5">
                          Un cadre propice à la
                          <span className="stroke">
                            {" "}
                            Spiritualité et à la Foi
                          </span>
                        </h1>

                        <p className="w-90 w-xl-60 mx-auto d-md-block">
                          L'Institut Saint Paul est dédié à l'approfondissement
                          de la foi chrétienne, inspiré par l'apôtre Paul
                        </p>

                        <div className="py-4 mt-n1-9">
                          <Link
                            to="/about"
                            className="butn white me-4 mt-1-9 d-sm-inline-block"
                          >
                            <i className="ti-arrow-right icon-arrow before white"></i>
                            <span className="label">En savoir plus</span>
                            <i className="ti-arrow-right icon-arrow after"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="text-center item bg-img primary-overlay"
                data-overlay-dark="8"
                data-background={banner02}
                style={{ backgroundImage: `url(${banner02})` }}
              >
                <div className="container h-100 w-100">
                  <div className="d-table h-100 w-100">
                    <div className="d-table-cell align-middle caption">
                      <div className="overflow-hidden w-95 w-md-85 w-lg-80 mt-md-5 mt-lg-5">
                        <h1 className="text-warning w-lg-100 w-xl-90 w-xxl-80 mx-auto pt-md-5 pt-lg-5">
                          Éducation
                          <span className="stroke"> & Formation</span>
                        </h1>

                        <p className="w-90 w-xl-60 mx-auto d-md-block">
                          L'accent est mis sur l'acquisition de connaissances et
                          de compétences pour une meilleure compréhension de la
                          foi.
                        </p>

                        <div className="py-4 mt-n1-9">
                          <Link
                            to="/about"
                            className="butn white me-4 mt-1-9 d-sm-inline-block"
                          >
                            <i className="ti-arrow-right icon-arrow before white"></i>
                            <span className="label">En savoir plus</span>
                            <i className="ti-arrow-right icon-arrow after"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="text-center item bg-img primary-overlay"
                data-overlay-dark="8"
                data-background={banner03}
                style={{ backgroundImage: `url(${banner03})` }}
              >
                <div className="container h-100 w-100">
                  <div className="d-table h-100 w-100">
                    <div className="d-table-cell align-middle caption">
                      <div className="overflow-hidden w-95 w-md-85 w-lg-80 mt-md-5 mt-lg-5">
                        <h1 className="text-warning w-lg-90 w-xl-80 w-xxl-70 mx-auto pt-md-5 pt-lg-5">
                          <span className="stroke">Communauté </span> &
                          <span className="stroke"> Engagement</span>
                        </h1>

                        <p className="w-90 w-xl-60 mx-auto d-md-block">
                          Les formations visent à outiller les participants à
                          partager et à transmettre leur foi aux autres.
                        </p>

                        <div className="py-4 mt-n1-9">
                          <Link
                            to="/about"
                            className="butn white me-4 mt-1-9 d-sm-inline-block"
                          >
                            <i className="ti-arrow-right icon-arrow before white"></i>
                            <span className="label">En savoir plus</span>
                            <i className="ti-arrow-right icon-arrow after"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            {/* OWL CAROUSEL */}
          </div>
        </div>

        {/* INFO
        ================================================== */}
        <section className="info-style-01">
          <div className="container justify-content-center">
            <div className="row mt-n1-9 aos-all justify-content-center">
              <div
                className="col-sm-6 col-lg-3 mt-1-9"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="flip-box">
                  <div className="flip-box-inner">
                    <div className="flip-box-front">
                      <div className="d-table-cell align-middle">
                        <img className="mb-4" src={icon01} alt="..." />
                        <h4 className="mb-0 font-weight-500">Éducation</h4>
                      </div>
                    </div>
                    <div className="flip-box-back">
                      <div className="d-table-cell align-middle">
                        <h4 className="mb-3 text-white">Éducation</h4>
                        <p className="text-white w-90 w-xl-80 mx-auto mb-3 opacity9">
                          Théologiques pour laïcs, religieuses & religieux
                        </p>
                        <Link className="link-btn white" to="/services">
                          <span className="link-text">Plus</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-sm-6 col-lg-3 mt-1-9"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="flip-box">
                  <div className="flip-box-inner">
                    <div className="flip-box-front">
                      <div className="d-table-cell align-middle">
                        <img className="mb-4" src={icon02} alt="..." />
                        <h4 className="mb-0 font-weight-500">Spiritualité</h4>
                      </div>
                    </div>
                    <div className="flip-box-back">
                      <div className="d-table-cell align-middle">
                        <h4 className="mb-3 text-white">Spiritualité</h4>
                        <p className="text-white w-90 w-xl-80 mx-auto mb-3 opacity9">
                          Approfondissement de la foi chrétienne
                        </p>
                        <Link className="link-btn white" to="/services">
                          <span className="link-text">plus</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-sm-6 col-lg-3 mt-1-9"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="flip-box">
                  <div className="flip-box-inner">
                    <div className="flip-box-front">
                      <div className="d-table-cell align-middle">
                        <img className="mb-4" src={icon03} alt="..." />
                        <h4 className="mb-0 font-weight-500">Communauté</h4>
                      </div>
                    </div>
                    <div className="flip-box-back">
                      <div className="d-table-cell align-middle">
                        <h4 className="mb-3 text-white">Communauté</h4>
                        <p className="text-white w-90 w-xl-80 mx-auto mb-3 opacity9">
                          Partager et à transmettre leur foi.
                        </p>
                        <Link className="link-btn white" to="/services">
                          <span className="link-text">Plus</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ABOUT US
        ================================================== */}
        <section className="about-style-01">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mb-5 mb-lg-0">
                <div className="mt-1-9">
                  <div className="row g-0 align-items-center">
                    <div className="col-8">
                      <div className="image1">
                        <img
                          src={about01}
                          alt="about01"
                          data-aos="fade-left"
                          data-aos-delay="200"
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="image2">
                        <img
                          src={about02}
                          alt="about02"
                          data-aos="fade-right"
                          data-aos-delay="300"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-6 ps-lg-2-5 ps-xl-7"
                data-aos="fade-in"
                data-aos-delay="100"
              >
                <h6 className="text-secondary display-28 mb-3">
                  <span className="me-2"></span>A propos
                </h6>

                <h2 className="h1 mb-4">
                  Bienvenue à
                  <span className="text-primary"> l'Institut Saint Paul</span>{" "}
                  (ISP)
                </h2>
                <p className="mb-1-9">
                  L'Institut Saint Paul est une œuvre diocésaine fondée en 1988,
                  placée sous le patronage de l'apôtre Paul, grand éducateur de
                  la foi.
                </p>

                <div className="row borders-bottom pb-4 mb-1-9 border-color-extra-light-gray">
                  <div className="col-sm-6 mb-3 mb-md-0">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <div className="mt-2">
                          <i className="ti-star text-secondary fs-1"></i>
                        </div>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h4 className="h5 mb-0">
                          {new Date().getFullYear() - 1988} Années d'existance
                        </h4>
                      </div>
                    </div>
                  </div>
                  {/* Non utilisé */}
                  <div className="col-sm-6 d-none">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <div className="mt-2">
                          <i className="ti-pie-chart text-secondary fs-1"></i>
                        </div>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h4 className="h5 mb-0">Practices For Industry</h4>
                      </div>
                    </div>
                  </div>
                  {/* Non utilisé */}
                </div>
                <div className="d-sm-flex align-items-center">
                  <div className="mb-4 mb-sm-0">
                    <Link to="/about" className="butn">
                      <i className="ti-arrow-right icon-arrow before"></i>
                      <span className="label">En savoir plus</span>
                      <i className="ti-arrow-right icon-arrow after"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* SERVICE
        ================================================== */}
        <section className="bg-light">
          <div className="container">
            <div
              className="section-heading"
              data-aos="fade-in"
              data-aos-delay="100"
            >
              <span className="alt-font sub-title">Services</span>
              <h2>
                Destinataires &<span className="text-primary"> objectifs</span>
              </h2>
              <span className="me-2">
                L'Institut propose des études systématiques en sciences humaines
                et théologiques...
              </span>
              <Link className="link-btn primary" to="/services">
                <span className="link-text">Plus</span>
              </Link>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-12 text-center">
                <div className="service-box-right">
                  <div className="service-carousel owl-carousel owl-theme">
                    {/* ITEMS */}
                    <div className="card card-style1">
                      <div className="card-body">
                        <img src={service01} alt="..." />
                        <div className="service-inner">
                          <div className="content">
                            <Link to="/services">
                              <h4>1ère année</h4>
                            </Link>
                            <p className="mb-0">
                              Certificat d'Étude de Base (CEB) à la fin de la
                              1ère année avec succès.
                            </p>
                            <div className="service-button">
                              <Link to="/services" className="butn md">
                                <i className="ti-arrow-right icon-arrow before"></i>
                                <span className="label">Lire plus</span>
                                <i className="ti-arrow-right icon-arrow after"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card card-style1">
                      <div className="card-body">
                        <img src={service02} alt="..." />
                        <div className="service-inner">
                          <div className="content">
                            <Link to="/services">
                              <h4>2ème année</h4>
                            </Link>
                            <p className="mb-0">
                              Certificat d'Étude Approfondies (CEA) à la fin de
                              la 2ème année avec succès.
                            </p>
                            <div className="service-button">
                              <Link to="/services" className="butn md">
                                <i className="ti-arrow-right icon-arrow before"></i>
                                <span className="label">Lire plus</span>
                                <i className="ti-arrow-right icon-arrow after"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card card-style1">
                      <div className="card-body">
                        <img src={service03} alt="..." />
                        <div className="service-inner">
                          <div className="content">
                            <Link to="/services">
                              <h4>3ème année</h4>
                            </Link>
                            <p className="mb-0">
                              Certificat de Fin d'Étude (CFE) à la fin de la
                              3ème année.
                            </p>
                            <div className="service-button">
                              <Link to="/services" className="butn md">
                                <i className="ti-arrow-right icon-arrow before"></i>
                                <span className="label">Lire plus</span>
                                <i className="ti-arrow-right icon-arrow after"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card card-style1">
                      <div className="card-body">
                        <img src={service04} alt="..." />
                        <div className="service-inner">
                          <div className="content">
                            <Link to="/services">
                              <h4>Brevet Théologique</h4>
                            </Link>
                            <p className="mb-0">
                              Décerné après réussite à l'examen et soutenance du
                              mémoire
                            </p>
                            <div className="service-button">
                              <Link to="/services" className="butn md">
                                <i className="ti-arrow-right icon-arrow before"></i>
                                <span className="label">Lire plus</span>
                                <i className="ti-arrow-right icon-arrow after"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* ITEMS */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* HOW WE WORK
        ================================================== */}
        <section>
          <div className="container">
            <div
              className="section-heading"
              data-aos="fade-in"
              data-aos-delay="100"
            >
              <span className="alt-font sub-title">Informations</span>
              <h2>
                Programm des cours par{" "}
                <span className="text-primary">année</span>
              </h2>
            </div>

            <div className="row mt-n1-9">

              <div className="col-sm-6 col-lg-4 mt-2-2">
                <div className="process-style1">
                  <div className="process-number-wrap">
                    <div className="process-number">01</div>
                  </div>
                  <div
                    className="text-center p-2"
                    data-aos="fade-zoom"
                    data-aos-delay="100"
                  >
                    <table className="table table-sm mb-0">
                      <thead>
                        <tr>
                          <th>
                            <span className="text-primary">#</span>
                          </th>
                          <th>
                            <span className="text-primary">Matières</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Christologie</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Mariologie</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Théologie fondamentale</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Introduction à la Bible (AT-NT)</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Sociologie</td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Morale fondamentale</td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>Liturgie</td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>Psychologie generale</td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td>laïcat</td>
                        </tr>
                        <tr>
                          <td>10</td>
                          <td>Catechese</td>
                        </tr>
                        <tr>
                          <td>11</td>
                          <td>Biorthique - Pastorale de la famille</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4 mt-2-2">
                <div className="process-style1">
                  <div className="process-number-wrap">
                    <div className="process-number">02</div>
                  </div>
                  <div
                    className="text-center p-2"
                    data-aos="fade-zoom"
                    data-aos-delay="100"
                  >
                    <table className="table table-sm mb-0">
                      <thead>
                        <tr>
                          <th>
                            <span className="text-primary">#</span>
                          </th>
                          <th>
                            <span className="text-primary">Matières</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Islamologie</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Ethique interpersonnelle</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Méthodologie</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Laïcat</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Théologie Biblique (AT-NT)</td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Doctrine sociale de l'Eglise</td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>Liturgie</td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>Foi et revelation</td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td>Decalogue</td>
                        </tr>
                        <tr>
                          <td>10</td>
                          <td>Histoire de l'Eglise</td>
                        </tr>
                        <tr>
                          <td>11</td>
                          <td>Ecclesiologie</td>
                        </tr>
                        <tr>
                          <td>12</td>
                          <td>Rhetorique et redaction de texte</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4 mt-2-2">
                <div className="process-style1 last">
                  <div className="process-number-wrap">
                    <div className="process-number">03</div>
                  </div>
                  <div
                    className="text-center p-2"
                    data-aos="fade-zoom"
                    data-aos-delay="100"
                  >
                    <table className="table table-sm mb-0">
                      <thead>
                        <tr>
                          <th>
                            <span className="text-primary">#</span>
                          </th>
                          <th>
                            <span className="text-primary">Matières</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Méthodologie</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Droit canonique & nariage</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Catechese</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Pastorale Biblique</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Pédagogie</td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Psychologie des relations</td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>Antrhopologie africaine</td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>Dynamique de groupe - communication</td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td>Patrologie</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center mt-5">
              <Link to="/services" className="butn md">
                <i className="ti-arrow-right icon-arrow before"></i>
                <span className="label">Voir plus</span>
                <i className="ti-arrow-right icon-arrow after"></i>
              </Link>
            </div>
          </div>
        </section>

        {/* PORTFOLIO
        ================================================== */}
        <section className="position-relative z-index-9 pb-0">
          <div
            className="parallax bg-img section-bg h-700 min-height-550 primary-overlay"
            data-overlay-dark="9"
            data-background="assets/bg/bg-01.jpg"
          ></div>
          <div className="container">
            <div className="row justify-content-center position-relative z-index-9">
              <div className="col-lg-12">
                <div className="text-center">
                  <h2 className="h1 text-white mb-1-6 w-md-80 w-lg-60 mx-auto text-capitalize">
                    Lieu & heures de cours
                  </h2>
                  <p className="mb-0 text-white display-29 display-lg-28 w-md-70 w-lg-65 mx-auto">
                    Les cours sont dispensés dans le bâtiment de l'ancienne
                    école professionnelle devenue
                    <span className="text-warning">
                      {" "}
                      Centre Christ Rédempteur{" "}
                    </span>{" "}
                    les lundi, mardi et mercredi de 18h15 à 21h00.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* TECHNOLOGIES */}
        <section
          className="bg-img primary-overlay cover-background parallax pt-0"
          data-overlay-dark="9"
        >
          <div className="container d-none">
            <div className="row">
              <div className="col-xl-12">
                <div className="extra-style1">
                  <div className="row justify-content-center">
                    <div className="col-md-8 text-center">
                      <h2 className="h1 text-warning font-weight-700 mb-4">
                        N.B. Le respect des mesure barrières contre la Covid-19
                        est de rigueur.
                      </h2>
                    </div>
                  </div>

                  <div className="icons">
                    <div className="icon-box">
                      <div className="position-relative">
                        <img src={icon52} alt="icon52" />
                      </div>
                    </div>

                    <div className="icon-box">
                      <div className="position-relative">
                        <img src={icon53} alt="icon53" />
                      </div>
                    </div>

                    <div className="icon-box">
                      <div className="position-relative">
                        <img src={icon54} alt="icon54" />
                      </div>
                    </div>

                    <div className="icon-box">
                      <div className="position-relative">
                        <img src={icon55} alt="icon55" />
                      </div>
                    </div>

                    <div className="icon-box">
                      <div className="position-relative">
                        <img src={icon56} alt="icon56" />
                      </div>
                    </div>

                    <div className="icon-box">
                      <div className="position-relative">
                        <img src={icon57} alt="icon57" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* TECHNOLOGIES */}

        {/* COUNTER
        ================================================== */}
        <section
          className="bg-img cover-background primary-overlay parallax"
          data-overlay-dark="9"
          data-background={bg02}
          style={{ backgroundImage: `url(${bg02})` }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mb-2-9 mb-sm-6 mb-lg-0">
                <div className="counter-number text-center">
                  <span className="countup">
                    {new Date().getFullYear() - 1988}
                  </span>
                  <h4 className="letter-spacing-5 text-white mb-0">
                    Ans d'expérience
                  </h4>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ps-lg-6 ps-xl-8">
                  <div className="row g-0 justify-content-center">
                    <div className="col-sm-6 text-center border-sm-end border-bottom border-white-opacity2 py-1-6">
                      <h3 className="text-white h1 font-weight-700">
                        <span className="countup">1160</span>+
                      </h3>
                      <p className="display-29 display-sm-28 display-xl-27 text-white font-weight-500 m-0">
                        Etudiants
                      </p>
                    </div>
                    <div className="col-sm-6 text-center border-bottom border-white-opacity2 py-1-6">
                      <h3 className="text-white h1 font-weight-700">
                        <span className="countup">200</span>+
                      </h3>
                      <p className="display-29 display-sm-28 display-xl-27 text-white font-weight-500 m-0">
                        Enseignants
                      </p>
                    </div>
                    <div className="col-sm-6 text-center borders-bottom border-sm-bottom-0 border-sm-end border-white-opacity2 py-1-6">
                      <h3 className="text-white h1 font-weight-700">
                        <span className="countup">1000</span>+
                      </h3>
                      <p className="display-29 display-sm-28 display-xl-27 text-white font-weight-500 m-0">
                        Diplômes
                      </p>
                    </div>
                    <div className="col-sm-6 text-center py-1-6">
                      <h3 className="countup text-white h1 font-weight-700">
                        5
                      </h3>
                      <p className="display-29 display-sm-28 display-xl-27 text-white font-weight-500 m-0">
                        Succusales
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* BLOG
        ================================================== */}
        <section>
          <div className="container">
            <div className="section-heading">
              <span className="alt-font sub-title">
                Activités & Informations
              </span>
              <h2>
                Vivez nos dernières
                <span className="text-primary"> activités</span>
              </h2>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-4 mb-1-6 mb-lg-0">
                <div
                  className="card card-style2 bg-img rounded h-100"
                  data-background="/assets/img/blog/blog-01.jpg"
                >
                  <div className="card-body">
                    <h6 className="text-white font-weight-500 mb-3 alt-font">
                      CONFERENCE-DEBAT
                    </h6>
                    <h3 className="h4 mb-0">
                      <Link to="/blog" className="text-white secondary-hover">
                        La gestion du nouveau code foncier et la sécurisation des terres au Togo.
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-4 col-md-12">
                <div className="row">
                  <div className="col-sm-6 col-lg-12 mb-1-6">
                    <div
                      className="card card-style2 bg-img cover-background rounded min-height-250"
                      data-background="/assets/img/blog/blog-02.jpg"
                    >
                      <div className="card-body">
                        <h6 className="text-white font-weight-500 mb-3 alt-font">
                          Messe
                        </h6>
                        <h3 className="h5 mb-0">
                          <Link
                            to="/blog"
                            className="text-white secondary-hover"
                          >
                            Messe d'ouverture
                          </Link>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-12 mb-1-6 mb-lg-0">
                    <div
                      className="card card-style2 bg-img cover-background rounded min-height-250"
                      data-background="/assets/img/blog/blog-03.jpg"
                    >
                      <div className="card-body">
                        <h6 className="text-white font-weight-500 mb-3 alt-font">
                          Recollection
                        </h6>
                        <h3 className="h5 mb-0">
                          <Link
                            to="/blog"
                            className="text-white secondary-hover"
                          >
                            Recollection
                          </Link>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-4">
                <div
                  className="card card-style2 bg-img cover-background rounded h-100"
                  data-background="../assets/blog/blog-04.jpg"
                >
                  <div className="card-body">
                    <h6 className="mb-3 text-white font-weight-500 alt-font d-none">
                      ...
                    </h6>
                    <h3 className="h4 mb-0">
                      <Link to="/blog" className="text-white secondary-hover">
                        Comment vous inscrire à l'ISP ?
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>

      {/* start scroll to top */}
      <Link to="#" className="scroll-to-top">
        <i className="fas fa-angle-up" aria-hidden="true"></i>
      </Link>
    </>
  );
};
