import React from "react";
import * as Media from "../media/Images";
import { Link } from "react-router-dom";

export default function Header(props) {
  return (
    <header 
      className={
        props.children !== null
          ? "header-style2"
          : "header-style1 menu_area-light"
      }
    >
      {props.children}
      <div className="navbar-default border-bottom border-color-light-white">
        <div className="top-search bg-secondary">
          <div className="container-fluid px-sm-1-6 px-lg-2-9">
            <form
              className="search-form"
              action="/"
              method="GET"
              acceptCharset="utf-8"
            >
              <div className="input-group">
                <span className="input-group-addon cursor-pointer">
                  <button
                    className="search-form_submit fas fa-search text-white"
                    type="submit"
                  ></button>
                </span>
                <input
                  type="text"
                  className="search-form_input form-control"
                  name="s"
                  placeholder="Type & hit enter..."
                />
                <span className="input-group-addon close-search mt-2">
                  <i className="fas fa-times"></i>
                </span>
              </div>
            </form>
          </div>
        </div>

        <div className="container-fluid px-sm-2-9">
          <div className="row align-items-center">
            <div className="col-12 col-lg-12">
              <div className="menu_area alt-font">
                <nav className="navbar navbar-expand-lg navbar-light p-0">
                  <div className="navbar-header navbar-header-custom">
                    <a href="/" className="navbar-brand">
                      <img id="logo" src={Media.logoInnerText} alt="logo" />
                    </a>
                    {/* end logo */}
                  </div>

                  <div className="navbar-toggler"></div>

                  {/* menu area */}
                  <ul
                    className="navbar-nav ms-auto"
                    id="nav"
                  >
                    <li>
                      <a href="/">Accueil</a>
                    </li>

                    <li>
                      <Link to="/about">A propos</Link>
                      <ul>
                        <li>
                          <Link to="/about">A propos</Link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <Link to="/blog">Informations</Link>
                      <ul>
                        <li>
                          <Link to="/about">Programmes</Link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <Link to="/services">Formations</Link>
                    </li>
                  </ul>
                  {/* end menu area */}

                  {/* start attribute navigation */}
                  <div className="attr-nav align-items-lg-center ms-lg-auto">
                    <ul>
                      <li className="d-none d-xl-inline-block">
                        <Link
                          to="/contacts"
                          className="butn secondary white-hover md"
                        >
                          <i className="ti-arrow-right icon-arrow before"></i>
                          <span className="label">Contacts</span>
                          <i className="ti-arrow-right icon-arrow after"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* end attribute navigation */}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
