import React, { useEffect } from "react";
import Header from "../../inc/Header";
import * as Media from "../../media/Images";
import Footer from "../../inc/Footer";
import { Infos } from "../../inc/Head";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

export default function Services() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
      mirror: false,
    });
  }, []);
  return (
    <div className="main-wrapper">
      <Header children={<Infos />} />
      {/* TITLE */}
      <section
        className="page-title-section bg-img cover-background primary-overlay"
        data-overlay-dark="7"
        data-background={Media.banner01}
        style={{ background: `url(${Media.service01})` }}
      >
        <div className="row position-relative z-index-1">
          <div className="col-md-12">
            <div className="faded-text">
              <div className="f-text">
                <span className="main-font">Formations</span>
              </div>
            </div>
            <h1>Formations</h1>
            <ul className="breadcrumb">
              <li>
                <a href="/">Accueil</a>
              </li>
              <li>
                <a href="/services">Formations</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* TITLE */}

      {/* SERVICES */}

      <section className="bg-white">
        <div className="container">
          <div
            className="section-heading"
            data-aos="fade-in"
            data-aos-delay="100"
          >
            <span className="alt-font sub-title">Informations</span>
            <h2>
              Programm des cours par{" "}
              <span className="text-primary">année</span>
            </h2>
          </div>

          <div className="row mt-n1-9">

            <div className="col-sm-6 col-lg-4 mt-2-2">
              <div className="process-style1">
                <div className="process-number-wrap">
                  <div className="process-number">01</div>
                </div>
                <div
                  className="text-center p-2"
                  data-aos="fade-zoom"
                  data-aos-delay="100"
                >
                  <table className="table table-sm mb-0">
                    <thead>
                      <tr>
                        <th>
                          <span className="text-primary">#</span>
                        </th>
                        <th>
                          <span className="text-primary">Matières</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Christologie</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Mariologie</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Théologie fondamentale</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Introduction à la Bible (AT-NT)</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Sociologie</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>Morale fondamentale</td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>Liturgie</td>
                      </tr>
                      <tr>
                        <td>8</td>
                        <td>Psychologie generale</td>
                      </tr>
                      <tr>
                        <td>9</td>
                        <td>laïcat</td>
                      </tr>
                      <tr>
                        <td>10</td>
                        <td>Catechese</td>
                      </tr>
                      <tr>
                        <td>11</td>
                        <td>Biorthique - Pastorale de la famille</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4 mt-2-2">
              <div className="process-style1">
                <div className="process-number-wrap">
                  <div className="process-number">02</div>
                </div>
                <div
                  className="text-center p-2"
                  data-aos="fade-zoom"
                  data-aos-delay="100"
                >
                  <table className="table table-sm mb-0">
                    <thead>
                      <tr>
                        <th>
                          <span className="text-primary">#</span>
                        </th>
                        <th>
                          <span className="text-primary">Matières</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Islamologie</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Ethique interpersonnelle</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Méthodologie</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Laïcat</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Théologie Biblique (AT-NT)</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>Doctrine sociale de l'Eglise</td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>Liturgie</td>
                      </tr>
                      <tr>
                        <td>8</td>
                        <td>Foi et revelation</td>
                      </tr>
                      <tr>
                        <td>9</td>
                        <td>Decalogue</td>
                      </tr>
                      <tr>
                        <td>10</td>
                        <td>Histoire de l'Eglise</td>
                      </tr>
                      <tr>
                        <td>11</td>
                        <td>Ecclesiologie</td>
                      </tr>
                      <tr>
                        <td>12</td>
                        <td>Rhetorique et redaction de texte</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4 mt-2-2">
              <div className="process-style1 last">
                <div className="process-number-wrap">
                  <div className="process-number">03</div>
                </div>
                <div
                  className="text-center p-2"
                  data-aos="fade-zoom"
                  data-aos-delay="100"
                >
                  <table className="table table-sm mb-0">
                    <thead>
                      <tr>
                        <th>
                          <span className="text-primary">#</span>
                        </th>
                        <th>
                          <span className="text-primary">Matières</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Méthodologie</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Droit canonique & nariage</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Catechese</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Pastorale Biblique</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Pédagogie</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>Psychologie des relations</td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>Antrhopologie africaine</td>
                      </tr>
                      <tr>
                        <td>8</td>
                        <td>Dynamique de groupe - communication</td>
                      </tr>
                      <tr>
                        <td>9</td>
                        <td>Patrologie</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="bg-light">
        <div className="container">
          <div
            className="section-heading"
            data-aos="fade-in"
            data-aos-delay="100"
          >
            <span className="alt-font sub-title">Services</span>
            <h2>
              Destinataires &<span className="text-primary"> objectifs</span>
            </h2>
            <span className="me-2">
              L'Institut propose à tous les fidèles laics, religieuses & religieuses une formationimportante d'initiation biblique, théologique, doctrinale, morale et spirituelle, etc.
            </span>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-12 text-center">
              <div className="service-box-right">
                <div className="service-carousel owl-carousel owl-theme">
                  {/* ITEMS */}
                  <div className="card card-style1">
                    <div className="card-body">
                      <img src={Media.service01} alt="..." />
                      <div className="service-inner">
                        <div className="content">
                          <a href="/formations">
                            <h4>1ère année</h4>
                          </a>
                          <p className="mb-0">
                            Certificat d'Étude de Base (CEB) à la fin de la 1ère
                            année avec succès.
                          </p>
                          <div className="service-button">
                            <a href="/formations" className="butn md">
                              <i className="ti-arrow-right icon-arrow before"></i>
                              <span className="label">Lire plus</span>
                              <i className="ti-arrow-right icon-arrow after"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card card-style1">
                    <div className="card-body">
                      <img src={Media.service02} alt="..." />
                      <div className="service-inner">
                        <div className="content">
                          <a href="/formations">
                            <h4>2ème année</h4>
                          </a>
                          <p className="mb-0">
                            Certificat d'Étude Approfondies (CEA) à la fin de la
                            2ème année avec succès.
                          </p>
                          <div className="service-button">
                            <a href="/formations" className="butn md">
                              <i className="ti-arrow-right icon-arrow before"></i>
                              <span className="label">Lire plus</span>
                              <i className="ti-arrow-right icon-arrow after"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card card-style1">
                    <div className="card-body">
                      <img src={Media.service03} alt="..." />
                      <div className="service-inner">
                        <div className="content">
                          <a href="/formations">
                            <h4>3ème année</h4>
                          </a>
                          <p className="mb-0">
                            Certificat de Fin d'Étude (CFE) à la fin de la 3ème
                            année.
                          </p>
                          <div className="service-button">
                            <a href="/formations" className="butn md">
                              <i className="ti-arrow-right icon-arrow before"></i>
                              <span className="label">Lire plus</span>
                              <i className="ti-arrow-right icon-arrow after"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card card-style1">
                    <div className="card-body">
                      <img src={Media.service04} alt="..." />
                      <div className="service-inner">
                        <div className="content">
                          <a href="/formations">
                            <h4>Brevet Théologique</h4>
                          </a>
                          <p className="mb-0">
                            Décerné après réussite à l'examen et soutenance du
                            mémoire
                          </p>
                          <div className="service-button">
                            <a href="/formations" className="butn md">
                              <i className="ti-arrow-right icon-arrow before"></i>
                              <span className="label">Lire plus</span>
                              <i className="ti-arrow-right icon-arrow after"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ITEMS */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* SERVICES */}

      <Footer />
    </div>
  );
}
