import React from "react";
import Header from "../../inc/Header";
import * as Media from "../../media/Images";
import Footer from "../../inc/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Infos } from "../../inc/Head";

export default function Contacts() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
      mirror: false,
    });
  }, []);
  return (
    <div className="main-wrapper">
      <Header children={<Infos />} />
      {/* TITLE */}
      <section
        className="page-title-section bg-img cover-background primary-overlay"
        data-overlay-dark="7"
        data-background={Media.banner01}
        style={{ background: `url(${Media.bg02})` }}
      >
        <div className="row position-relative z-index-1">
          <div className="col-md-12">
            <div className="faded-text">
              <div className="f-text">
                <span className="main-font">Contacts</span>
              </div>
            </div>
            <h1>Nous contacter</h1>
            <ul className="breadcrumb">
              <li>
                <a href="/">Accueil</a>
              </li>
              <li>
                <a href="/contacts">Contacts</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* TITLE */}
      <section className="bg-light">
        <div className="container">
          <div className="row justify-content-center mt-3">
            <div className="col-lg-7 mb-2-2 mb-lg-0">
              <div className="p-1-9 p-lg-2-2 box-shadows h-100">
                <h2 className="mb-2-5 text-primary">Contactez nous</h2>
                <form
                  className="contact quform"
                  action="https://techno.websitelayout.net/quform/contact.php"
                  method="post"
                  enctype="multipart/form-data"
                  onclick=""
                >
                  <div className="quform-elements">
                    <div className="row">
                      {/* <!-- Begin Text input element --> */}
                      <div className="col-md-6">
                        <div className="quform-element form-group">
                          <label for="name">
                            Votre nom <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <input
                              className="form-control"
                              id="name"
                              type="text"
                              name="name"
                              placeholder="Votre nom"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <!-- End Text input element --> */}

                      {/* <!-- Begin Text input element --> */}
                      <div className="col-md-6">
                        <div className="quform-element form-group">
                          <label for="email">
                            Votre email{" "}
                            <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <input
                              className="form-control"
                              id="email"
                              type="text"
                              name="email"
                              placeholder="Votre email"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <!-- End Text input element --> */}

                      {/* <!-- Begin Text input element --> */}
                      <div className="col-md-6">
                        <div className="quform-element form-group">
                          <label for="subject">
                            Sujet <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <input
                              className="form-control"
                              id="subject"
                              type="text"
                              name="subject"
                              placeholder="Your subject here"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <!-- End Text input element --> */}

                      {/* <!-- Begin Text input element --> */}
                      <div className="col-md-6">
                        <div className="quform-element form-group">
                          <label for="phone">Votre contact</label>
                          <div className="quform-input">
                            <input
                              className="form-control"
                              id="phone"
                              type="text"
                              name="phone"
                              placeholder="Votre contact"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <!-- End Text input element --> */}

                      {/* <!-- Begin Textarea element --> */}
                      <div className="col-md-12">
                        <div className="quform-element form-group">
                          <label for="message">
                            Message <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows="3"
                              placeholder="Votre message"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      {/* <!-- End Textarea element --> */}

                      {/* <!-- Begin Submit button --> */}
                      <div className="col-md-12">
                        <div className="quform-submit-inner">
                          <button className="butn border-0" type="submit">
                            <i className="ti-arrow-right icon-arrow before"></i>
                            <span className="label">Send Message</span>
                            <i className="ti-arrow-right icon-arrow after"></i>
                          </button>
                        </div>
                        <div className="quform-loading-wrap text-start">
                          <span className="quform-loading"></span>
                        </div>
                      </div>
                      {/* <!-- End Submit button --> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="box-shadows p-1-9 p-lg-2-2 h-100">
                <h2 className="mb-3 text-primary text-capitalize h3">
                  Détails
                </h2>
                <p className="mb-2-2">
                  <span className="text-primary text-capitalize">
                    Archidiocese de Lomé
                  </span>
                  , Institut de Pédagogique Réligieuse Saint Paul de lomé.
                </p>
                <div className="d-flex mb-4 pb-3 border-bottom border-color-extra-light-gray">
                  <div className="flex-shrink-0 mt-2">
                    <i className="fas fa-phone-alt text-secondary fs-2"></i>
                  </div>
                  <div className="flex-grow-1 ms-4">
                    <h3 className="h5">Tél</h3>
                    <span className="text-muted d-block mb-1">
                      (00228) 92 77 48 99
                    </span>
                    <span className="text-muted">(00228) 97 2221 72</span>
                  </div>
                </div>
                <div className="d-flex mb-4 pb-3 border-bottom border-color-extra-light-gray">
                  <div className="flex-shrink-0 mt-2">
                    <i className="far fa-envelope-open text-secondary fs-2"></i>
                  </div>
                  <div className="flex-grow-1 ms-4">
                    <h3 className="h5 mb-1">Adresse mail</h3>
                    <span className="text-muted">BP: 2698 Lomé-TOGO</span>
                    <span className="text-muted d-block mb-1">
                      contact@isplome.org
                    </span>
                  </div>
                </div>
                <div className="d-flex mb-4 pb-3 border-bottom border-color-extra-light-gray">
                  <div className="flex-shrink-0 mt-2">
                    <i className="fas fa-map-marker-alt text-secondary fs-2"></i>
                  </div>
                  <div className="flex-grow-1 ms-4">
                    <h3 className="h5 mb-1">Localisation</h3>
                    <address className="text-muted d-block mb-0 w-md-80 w-xl-70">
                      <span className="text-dark">
                        Ancienne Ecole Professionnelle sise à Brother Home
                      </span>
                    </address>
                  </div>
                </div>
                <ul className="social-box">
                  <li>
                    <a href="#!">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <iframe
        className="map"
        id="gmap_canvas"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3967.0081272038356!2d1.2243961781752077!3d6.129607660759854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1023e1c27c978a93%3A0xeb74cce3d70b1bb2!2sCentre%20Christ%20Redempteur%20BrotherHome!5e0!3m2!1sfr!2stg!4v1719928553022!5m2!1sfr!2stg"
        width="600"
        height="450"
        style={{border:"0"}}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        >
        </iframe>

      {/* FOOTER */}
      <Footer />
    </div>
  );
}
