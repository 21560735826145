import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/plugins.css";
import "./assets/search/search.css";
import "./assets/quform/css/base.css";
import "./assets/css/styles.css";
import App from "./App";

import { HashRouter as Router } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);