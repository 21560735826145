import React from "react";
import Header from "../../inc/Header";
import * as Media from "../../media/Images";
import Footer from "../../inc/Footer";
import { Infos } from "../../inc/Head";

export default function BlogDetails() {
  return (
    <div className="main-wrapper">
      <Header children={<Infos />} />
      {/* TITLE */}
      <section
        className="page-title-section bg-img cover-background primary-overlay"
        data-overlay-dark="7"
        data-background={Media.banner01}
        style={{ background: `url(${Media.bg02})` }}
      >
        <div className="row position-relative z-index-1">
          <div className="col-md-12">
            <div className="faded-text">
              <div className="f-text">
                <span className="main-font">Infos</span>
              </div>
            </div>
            <h1>Informations</h1>
            <ul className="breadcrumb">
              <li>
                <a href="/">Accueil</a>
              </li>
              <li>
                <a href="/blog">Blog</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* TITLE */}

      {/* BLOG */}
      <section>
        <div className="container">
          <div className="row">
            {/* <!-- blog-details left --> */}
            <div className="col-lg-8 mb-5 mb-lg-0">
              <div className="pe-lg-1-9">
                <div className="row">
                  <div className="col-lg-12 mb-5">
                    <article className="card card-style10">
                      <div className="image-box">
                        <img
                          src="/assets/img/blog/blog-list-01.jpg"
                          alt="..."
                        />
                        <div className="date">
                          <a href="#!">Dec</a>
                          <a href="#!">05</a>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="category">
                          <span>Web Development</span>
                        </div>
                        <h3 className="h4 mb-3">
                          <a href="blog-detail.html">
                            Visual Identity System Is More Memorable.
                          </a>
                        </h3>
                        <ul className="list-unstyled border-bottom pb-3 mb-3">
                          <li className="last">
                            <i className="far fa-comment-dots me-2 text-secondary"></i>
                            <a href="#!">10 Comment</a>
                          </li>
                        </ul>
                        <p className="w-lg-95">
                          Software is a fixed of instructions, records or
                          applications used to function computer systems and
                          execute unique tasks. It is the other of hardware,
                          which describes the bodily components of a computer.
                          Software is a conventional time period used to consult
                          applications, scripts and applications that run on a
                          device. It may be concept of because the variable a
                          part of a computer, even as hardware is the invariable
                          part.
                        </p>
                        <p className="mb-0 w-lg-95">
                          The most important classes of software program are
                          software software program and machine software
                          program. An software is software program that fulfills
                          a particular want or plays tasks. System software
                          program is designed to run a computer's hardware and
                          offers a platform for programs to run on pinnacle of.
                        </p>
                        <blockquote>
                          <p className="font-weight-500 lead">
                            further to guide in coping with your IT systems, IT
                            carrier vendors also can assist with defining and
                            enforcing your destiny IT projects.
                          </p>
                          <cite>– Jordan Nicholls</cite>
                        </blockquote>
                        <h3 className="mb-3 text-primary">
                          Strategy For Providing Latest It Solution
                        </h3>
                        <p className="w-lg-95 mb-1-9">
                          Other forms of software program consist of programming
                          software program, which presents the programming gear
                          software program builders need; middleware, which sits
                          among machine software program and applications; and
                          driving force software program, which operates pc
                          gadgets and peripherals.
                        </p>

                        <div className="row mb-1-9">
                          <div className="col-md-6 mb-4 mb-md-0">
                            <img
                              src="/assets/img/blog/blog-detail-02.jpg"
                              alt="..."
                            />
                          </div>
                          <div className="col-md-6">
                            <img
                              src="/assets/img/blog/blog-detail-03.jpg"
                              alt="..."
                            />
                          </div>
                        </div>
                        <div className="row mb-2-2">
                          <div className="col-lg-12">
                            <p className="mb-4 w-lg-95">
                              Over the remaining decades, software program
                              providers have moved farfar from promoting
                              software program licenses on a one-time foundation
                              to a software program-as-a-carrier subscription
                              model. Software providers host the software
                              program withinside the cloud and make it to be had
                              to customers, who pay a subscription price and get
                              entry to the software program over the internet.
                            </p>
                            <ul className="list-style3 mb-0">
                              <li>
                                {" "}
                                It is the other of hardware, which describes the
                                bodily components of a computer.
                              </li>
                              <li>
                                It may be concept of because the variable a part
                                of a computer.
                              </li>
                              <li>
                                An software is software program that fulfills a
                                particular want or plays tasks.
                              </li>
                              <li>
                                These groups install cybersecurity measures to
                                shield the data.
                              </li>
                              <li>
                                The provider companies will deal with all of the
                                information dealing with and dealing.
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="row mb-1-9">
                          <div className="col-xl-6 mb-1-9 mb-xl-0">
                            <img
                              src="/assets/img/blog/blog-detail-04.jpg"
                              alt="..."
                            />
                          </div>
                          <div className="col-xl-6">
                            <div className="ps-xl-2">
                              <h3 className="h4 text-primary">What We Do ?</h3>
                              <p>
                                IWe’re a team of creatives who are excited about
                                unique ideas and help digital and fin-tech
                                companies to create amazing identity.
                              </p>
                              <ul className="list-style3 mb-0">
                                <li>
                                  Assess your business for bigger success.
                                </li>
                                <li>We Provide World Professional Services.</li>
                                <li>Every Day Is New Challange For Us.</li>
                                <li>We Create Amazing Identity.</li>
                                <li>
                                  We Provide Also Cyber Security Services.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <p className="w-lg-95 mb-0">
                              These computer packages are set up on a user's
                              laptop and use the laptop reminiscence to perform
                              tasks. They soak up area at the laptop's difficult
                              power and do now no longer want a web connection
                              to work. However, computer packages ought to
                              adhere to the necessities of the hardware gadgets
                              they run on.
                            </p>
                          </div>
                        </div>
                        <div className="separator"></div>
                        <div className="row g-0 align-items-center">
                          <div className="col-md-6 col-xs-12 mb-1-6 mb-md-0">
                            <div className="tags">
                              <h6 className="h5 mb-3 text-primary">
                                Related Tags
                              </h6>
                              <ul className="blog-tags">
                                <li className="d-inline-block">
                                  <a href="#!">IT Service</a>
                                </li>
                                <li className="d-inline-block">
                                  <a href="#!">Cloud</a>
                                </li>
                                <li className="d-inline-block">
                                  <a href="#!">Cyber</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6 col-xs-12">
                            <h6 className="h5 text-md-end text-start mb-3 text-primary">
                              Share Post
                            </h6>
                            <div className="text-end">
                              <a href="#!" className="me-3">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href="#!" className="me-3">
                                <i className="fab fa-twitter"></i>
                              </a>
                              <a href="#!" className="me-3">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="#!" className="me-3">
                                <i className="fab fa-youtube"></i>
                              </a>
                              <a href="#!">
                                <i className="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-12 mb-5">
                    <div className="card card-style16">
                      <div className="card-body p-0 mt-0 border-0 box-shadow-none">
                        <div className="author-info-wrapper">
                          <div className="author-info-avatar">
                            <img
                              src="/assets/img/avatar/avatar-31.jpg"
                              alt="..."
                            />
                          </div>
                          <div className="author-info-content">
                            <h4 className="h5 mb-1 text-primary">
                              Topi Kuosmanen
                            </h4>
                            <span className="small text-muted d-block mb-3">
                              25 Dec 2021
                            </span>
                            <p className="w-sm-95 mx-auto mb-0">
                              We've used technology for the last five years. I
                              can't say enough about technology. Without
                              technology, we would have gone bankrupt by now.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-5">
                    <div className="card card-style16">
                      <div className="card-body border-0 p-1-6 p-sm-1-9 p-md-2-2 mt-0">
                        {/* <!-- comment --> */}
                        <div className="row mb-2-9 g-0">
                          <div className="col-lg-12">
                            <h3 className="h4 mb-4 text-primary">Comments</h3>
                            <div className="d-flex pb-4 mb-5 border-color-extra-light-gray border-bottom">
                              <div className="flex-shrink-0">
                                <a href="#!">
                                  <img
                                    className="border-radius-50"
                                    src="/assets/img/avatar/avatar-32.jpg"
                                    alt="..."
                                  />
                                </a>
                              </div>
                              <div className="flex-grow-1 ms-4">
                                <h4 className="h6">
                                  Josefiina Ukkonen{" "}
                                  <span className="small ms-2 text-muted">
                                    05 Days ago
                                  </span>
                                </h4>
                                <p className="mb-3 w-lg-95">
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco voluptas sit aspernatur
                                  au laboris nisi ut aliquip ex ea commodo
                                  consequat.
                                </p>
                                <a href="#!" className="font-weight-500">
                                  <i className="fa fa-reply display-31 pe-2"></i>{" "}
                                  Reply
                                </a>
                              </div>
                            </div>
                            <div className="d-flex pb-4 mb-5 border-color-extra-light-gray border-bottom">
                              <div className="flex-shrink-0">
                                <a href="#!">
                                  <img
                                    className="border-radius-50"
                                    src="/assets/img/avatar/avatar-33.jpg"
                                    alt="..."
                                  />
                                </a>
                              </div>
                              <div className="flex-grow-1 ms-4">
                                <h4 className="h6">
                                  Maarit Utrio{" "}
                                  <span className="small ms-2 text-muted">
                                    08 days ago
                                  </span>
                                </h4>
                                <p className="mb-3 w-lg-95">
                                  Thank you so much for your good words.
                                </p>
                                <a href="#!" className="font-weight-500">
                                  <i className="fa fa-reply display-31 pe-2"></i>{" "}
                                  Reply
                                </a>
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="flex-shrink-0">
                                <a href="#!">
                                  <img
                                    className="border-radius-50"
                                    src="/assets/img/avatar/avatar-34.jpg"
                                    alt="..."
                                  />
                                </a>
                              </div>
                              <div className="flex-grow-1 ms-4">
                                <h4 className="h6">
                                  Aamos Kankkunen{" "}
                                  <span className="small ms-2 text-muted">
                                    13 Days ago
                                  </span>
                                </h4>
                                <p className="mb-3 w-lg-95">
                                  It is a long established fact that a reader
                                  will be distracted by the readable content of
                                  a page when looking at its layout.
                                </p>
                                <a href="#!" className="font-weight-500">
                                  <i className="fa fa-reply display-31 pe-2"></i>{" "}
                                  Reply
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- end comment--> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="card card-style16">
                      <div className="card-body border-0 p-1-6 p-sm-1-9 p-md-2-2 mt-0">
                        {/* <!--  start form--> */}
                        <div className="row g-0">
                          <div className="col-lg-12">
                            <h3 className="h4 mb-4 text-primary">
                              Post A Comment
                            </h3>
                            <form>
                              <div className="form-row">
                                <div className="col-sm">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="name"
                                      placeholder="Your Name"
                                    />
                                  </div>
                                </div>
                                <div className="col-sm">
                                  <div className="form-group">
                                    <input
                                      type="email"
                                      className="form-control"
                                      name="email"
                                      placeholder="Email Address"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <textarea
                                  name="reply"
                                  rows="6"
                                  className="form-control"
                                  placeholder="Your Reply"
                                ></textarea>
                              </div>
                              <div>
                                <button className="butn border-0" type="submit">
                                  <i className="ti-arrow-right icon-arrow before"></i>
                                  <span className="label">Post Comment</span>
                                  <i className="ti-arrow-right icon-arrow after"></i>
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                        {/* <!--  end form--> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end blog-details left --> */}

            {/* <!-- blog-details right --> */}
            <div className="col-lg-4">
              <div className="blog-sidebar">
                <div className="widget search">
                  <h6 className="widget-title">
                    <span className="me-2">//</span>Search
                  </h6>
                  <form action="#!" className="search-bar">
                    <input
                      type="search"
                      name="search"
                      placeholder="Type here ..."
                    />
                    <a href="#!" className="btn-newsletter">
                      <i className="fas fa-search"></i>
                    </a>
                  </form>
                </div>
                <div className="widget widget-reviews">
                  <h6 className="widget-title">
                    <span className="me-2">//</span>Clients
                  </h6>
                  <div className="blog-client owl-carousel owl-theme">
                    <div>
                      <div className="d-flex align-items-center mb-4">
                        <div className="flex-shrink-0">
                          <img
                            className="rounded-circle"
                            src="/assets/img/avatar/avatar-28.jpg"
                            alt="..."
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <span className="mb-2 d-block">
                            <i className="fas fa-quote-left text-secondary fs-4"></i>
                          </span>
                          <h5 className="h6 mb-1">Dyanne Zweep</h5>
                          <p className="mb-0 display-31">Customer</p>
                        </div>
                      </div>
                      <p className="mb-0">
                        Technology has impacted society is through
                        communication, how we talk with each other worldwide.
                      </p>
                    </div>
                    <div>
                      <div className="d-flex align-items-center mb-4">
                        <div className="flex-shrink-0">
                          <img
                            className="rounded-circle"
                            src="/assets/img/avatar/avatar-29.jpg"
                            alt="..."
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <span className="mb-2 d-block">
                            <i className="fas fa-quote-left text-secondary fs-4"></i>
                          </span>
                          <h5 className="h6 mb-1">Temshe Mustafa</h5>
                          <p className="mb-0 display-31">Customer</p>
                        </div>
                      </div>
                      <p className="mb-0">
                        Technology has impacted society is through
                        communication, how we talk with each other worldwide.
                      </p>
                    </div>
                    <div>
                      <div className="d-flex align-items-center mb-4">
                        <div className="flex-shrink-0">
                          <img
                            className="rounded-circle"
                            src="/assets/img/avatar/avatar-30.jpg"
                            alt="..."
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <span className="mb-2 d-block">
                            <i className="fas fa-quote-left text-secondary fs-4"></i>
                          </span>
                          <h5 className="h6 mb-1">Selassie Russom</h5>
                          <p className="mb-0 display-31">Customer</p>
                        </div>
                      </div>
                      <p className="mb-0">
                        Technology has impacted society is through
                        communication, how we talk with each other worldwide.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="widget widget-nav-menu">
                  <h6 className="widget-title">
                    <span className="me-2">//</span>Categories
                  </h6>
                  <ul className="cat-list">
                    <li>
                      <a href="#!">IT Consultancy</a>
                      <span>03</span>
                    </li>
                    <li>
                      <a href="#!">Cloud Computing</a>
                      <span>18</span>
                    </li>
                    <li>
                      <a href="#!">Threat Hunter</a>
                      <span>10</span>
                    </li>
                    <li>
                      <a href="#!">Artifical Intelligence</a>
                      <span>13</span>
                    </li>
                    <li>
                      <a href="#!">Network Security</a>
                      <span>25</span>
                    </li>
                    <li>
                      <a href="#!">Digital Technology</a>
                      <span>18</span>
                    </li>
                  </ul>
                </div>
                <div className="widget widget-post">
                  <h6 className="widget-title">
                    <span className="me-2">//</span>Latest Posts
                  </h6>
                  <div className="blog-post-carousel owl-carousel owl-theme">
                    <div>
                      <div className="image-box">
                        <img
                          src="/assets/img/blog/blog-post-01.jpg"
                          alt="..."
                        />
                        <h6>Cyber Security</h6>
                      </div>
                      <div className="post-content">
                        <h4 className="h6">
                          <a href="blog-details.html">
                            Protect workplace from cyber.
                          </a>
                        </h4>
                        <a href="#!" className="display-30">
                          Dec 23, 2021
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="image-box">
                        <img
                          src="/assets/img/blog/blog-post-02.jpg"
                          alt="..."
                        />
                        <h6>IT Projects</h6>
                      </div>
                      <div className="post-content">
                        <h4 className="h6">
                          <a href="blog-details.html">
                            How to build and launch powerful.
                          </a>
                        </h4>
                        <a href="#!" className="display-30">
                          Dec 18, 2021
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="image-box">
                        <img
                          src="/assets/img/blog/blog-post-03.jpg"
                          alt="..."
                        />
                        <h6>Mobile Apps</h6>
                      </div>
                      <div className="post-content">
                        <h4 className="h6">
                          <a href="blog-details.html">
                            We work with you to business.
                          </a>
                        </h4>
                        <a href="#!" className="display-30">
                          Dec 14, 2021
                        </a>
                      </div>
                    </div>
                    <div>
                      <div className="image-box">
                        <img
                          src="/assets/img/blog/blog-post-04.jpg"
                          alt="..."
                        />
                        <h6>Development</h6>
                      </div>
                      <div className="post-content">
                        <h4 className="h6">
                          <a href="blog-details.html">
                            The essentiaaals of cybersecurity solutions.
                          </a>
                        </h4>
                        <a href="#!" className="display-30">
                          Dec 11, 2021
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="widget widget-gallary">
                  <h6 className="widget-title">
                    <span className="me-2">//</span>Gallery
                  </h6>
                  <div className="row g-2">
                    <div className="col-3 col-lg-4">
                      <div className="blog-insta position-relative">
                        <a href="#!">
                          <img
                            src="/assets/img/blog/blog-thumb-01.jpg"
                            alt="..."
                          />
                          <div className="hover-icon">
                            <i className="ti-instagram text-white fs-5"></i>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-3 col-lg-4">
                      <div className="blog-insta position-relative">
                        <a href="#!">
                          <img
                            src="/assets/img/blog/blog-thumb-02.jpg"
                            alt="..."
                          />
                          <div className="hover-icon">
                            <i className="ti-instagram text-white fs-5"></i>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-3 col-lg-4">
                      <div className="blog-insta position-relative">
                        <a href="#!">
                          <img
                            src="/assets/img/blog/blog-thumb-03.jpg"
                            alt="..."
                          />
                          <div className="hover-icon">
                            <i className="ti-instagram text-white fs-5"></i>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-3 col-lg-4">
                      <div className="blog-insta position-relative">
                        <a href="#!">
                          <img
                            src="/assets/img/blog/blog-thumb-04.jpg"
                            alt="..."
                          />
                          <div className="hover-icon">
                            <i className="ti-instagram text-white fs-5"></i>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-3 col-lg-4">
                      <div className="blog-insta position-relative">
                        <a href="#!">
                          <img
                            src="/assets/img/blog/blog-thumb-05.jpg"
                            alt="..."
                          />
                          <div className="hover-icon">
                            <i className="ti-instagram text-white fs-5"></i>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-3 col-lg-4">
                      <div className="blog-insta position-relative">
                        <a href="#!">
                          <img
                            src="/assets/img/blog/blog-thumb-06.jpg"
                            alt="..."
                          />
                          <div className="hover-icon">
                            <i className="ti-instagram text-white fs-5"></i>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="widget widget-tag">
                  <h6 className="widget-title">
                    <span className="me-2">//</span>Popular Tags
                  </h6>
                  <ul className="blog-tags">
                    <li>
                      <a href="#!">Business</a>
                    </li>
                    <li>
                      <a href="#!">Cloud</a>
                    </li>
                    <li>
                      <a href="#!">Software</a>
                    </li>
                    <li>
                      <a href="#!">Machine Learning</a>
                    </li>
                    <li>
                      <a href="#!">Technology</a>
                    </li>
                    <li>
                      <a href="#!">Website</a>
                    </li>
                    <li>
                      <a href="#!">IT Services</a>
                    </li>
                    <li>
                      <a href="#!">Security</a>
                    </li>
                  </ul>
                </div>
                <div className="widget widget-social-icon">
                  <h6 className="widget-title">
                    <span className="me-2">//</span>Follow Us
                  </h6>
                  <ul className="social-box">
                    <li>
                      <a href="#!">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#!">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#!">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#!">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#!">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- end blog-details right --> */}
          </div>
        </div>
      </section>
      {/* BLOG */}

      <Footer />
    </div>
  );
}
