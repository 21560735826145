import React from "react";
import * as Media from "../media/Images";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer
      className="footer1 bg-img cover-background pt-2-5 pt-sm-6 pt-md-10"
      data-overlay-dark="8"
      data-background={Media.bg03}
      style={{ backgroundImage: `url(${Media.bg03})` }}
    >
      <div className="container mb-6 pb-2-5 pb-md-5 border-bottom border-color-light-white">
        <div className="row justify-content-center mt-n1-9">
          <div className="col-sm-6 col-lg-4 mt-1-9 border-sm-end border-color-light-white">
            <div className="text-sm-center">
              <i className="fas fa-phone-alt text-white fs-2 d-block mb-4"></i>
              <h3 className="h6 text-white">Tel</h3>
              <span className="text-white opacity9">
                (00228) 92774899 / 97222172
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 mt-1-9 border-lg-end border-color-light-white">
            <div className="text-sm-center">
              <i className="far fa-envelope-open text-white fs-2 d-block mb-4"></i>
              <h3 className="h6 text-white">Mail</h3>
              <span className="text-white opacity9">BP: 2698 Lomé-TOGO</span>
              <br />
              <span className="text-white opacity9">contact@isplome.org</span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 mt-1-9">
            <div className="text-sm-center">
              <i className="fas fa-map-marker-alt text-white fs-2 d-block mb-4"></i>
              <h3 className="h6 text-white">Adresse</h3>
              <span className="text-white opacity9">
                Ancienne Ecole Professionnelle sise à
              </span>{" "}
              <br />
              <span className="text-dark"> Brother Home</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mt-n2-6">
          <div className="col-sm-6 col-lg-7 mt-2-6">
            <div className="footer-logo mb-3">
              <img src={Media.footerLightLogoBack} alt="logo" />
            </div>
            <p className="text-white mb-1-6 mb-lg-1-9 opacity9">
              L'Institut Saint Paul est une œuvre diocésaine fondée en 1988,
              placée sous le patronage de l'apôtre Paul, grand éducateur de la
              foi.
            </p>
            <form
              className="quform newsletter-form w-90 w-sm-100"
              action="#"
              method="post"
              encType="multipart/form-data"
            >
              <div className="quform-elements">
                <div className="row">
                  {/* Begin Text input element */}
                  <div className="col-md-12">
                    <div className="quform-element">
                      <div className="quform-input">
                        <input
                          className="form-control"
                          id="email_address"
                          type="text"
                          name="email_address"
                          placeholder="Restez informé"
                        />
                      </div>
                    </div>
                  </div>
                  {/* End Text input element */}

                  {/* Begin Submit button */}
                  <div className="col-md-12">
                    <div className="quform-submit-inner">
                      <button className="btn btn-white m-0" type="submit">
                        <span>
                          <i className="fas fa-paper-plane text-white"></i>
                        </span>
                      </button>
                    </div>
                    <div className="quform-loading-wrap text-start">
                      <span className="quform-loading"></span>
                    </div>
                  </div>
                  {/* End Submit button */}
                </div>
              </div>
            </form>
          </div>

          <div className="col-sm-6 col-lg-5 mt-2-6">
            <div className="ps-lg-1-9 ps-xl-6">
              <h3 className="h5 text-white text-capitalize mb-1-6 mb-lg-1-9">
                Liens utiles
              </h3>
              <ul className="footer-list-style1">
                <li>
                  <Link to="/about">A PROPOS</Link>
                </li>
                <li>
                  <Link to="/services">INFORMATIONS</Link>
                </li>
                <li>
                  <Link to="/blog">ACTIVITES</Link>
                </li>
                <li>
                  <Link to="https://school.semfield.com">INTRANET</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bar">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 text-center text-md-start mt-3 mt-md-0 order-2 order-md-1">
              <p className="d-inline-block text-white mb-0 display-30 display-lg-29">
                &copy; <span className="current-year"></span> Powered by{" "}
                <Link
                  to="https://www.semfield.com"
                  target="_blank"
                  rel="noreferrer"
                  className="text-dark white-hover"
                >
                  SEMFIELD SARL U
                </Link>
              </p>
            </div>
            <div className="col-md-6 text-md-end order-1 order-md-2">
              <ul className="footer-social-style1">
                <li>
                  <Link to="#!">
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#!">
                    <i className="fab fa-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#!">
                    <i className="fab fa-youtube"></i>
                  </Link>
                </li>
                <li>
                  <Link href="#!">
                    <i className="fab fa-linkedin-in"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
