import React from "react";
import Header from "../../inc/Header";
import * as Media from "../../media/Images";
import Footer from "../../inc/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Infos } from "../../inc/Head";

export default function About() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
      mirror: false,
    });
  }, []);
  return (
    <div className="main-wrapper">
      <Header children={<Infos />} />
      {/* TITLE */}
      <section
        className="page-title-section bg-img cover-background primary-overlay"
        data-overlay-dark="7"
        data-background={Media.banner01}
        style={{ background: `url(${Media.bg02})` }}
      >
        <div className="row position-relative z-index-1">
          <div className="col-md-12">
            <div className="faded-text">
              <div className="f-text">
                <span className="main-font">À propos</span>
              </div>
            </div>
            <h1>À Propos</h1>
            <ul className="breadcrumb">
              <li>
                <a href="/">Accueil</a>
              </li>
              <li>
                <a href="/about">À propos</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* TITLE */}
      {/* ABOUT */}
      <section className="about-style-01">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <div className="mt-1-9">
                <div className="row g-0 align-items-center">
                  <div className="col-8">
                    <div className="image1">
                      <img
                        src={Media.about01}
                        alt="about01"
                        data-aos="fade-left"
                        data-aos-delay="200"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="image2">
                      <img
                        src={Media.about02}
                        alt="about02"
                        data-aos="fade-right"
                        data-aos-delay="300"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-6 ps-lg-2-5 ps-xl-7"
              data-aos="fade-in"
              data-aos-delay="100"
            >
              <h6 className="text-secondary display-28 mb-3">
                <span className="me-2"></span>A propos
              </h6>

              <h2 className="h1 mb-4">
                Bienvenue à
                <span className="text-primary"> l'Institut Saint Paul</span>{" "}
                (ISP)
              </h2>
              <p className="mb-1-9">
                L'Institut Saint Paul est une œuvre diocésaine fondée en 1988,
                placée sous le patronage de l'apôtre Paul, grand éducateur de la
                foi.
              </p>

              <div className="row borders-bottom pb-4 mb-1-9 border-color-extra-light-gray">
                <div className="col-sm-6 mb-3 mb-md-0">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <div className="mt-2">
                        <i className="ti-star text-secondary fs-1"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h4 className="h5 mb-0">
                        {new Date().getFullYear() - 1988} Années d'existance
                      </h4>
                    </div>
                  </div>
                </div>
                {/* Non utilisé */}
                <div className="col-sm-6 d-none">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <div className="mt-2">
                        <i className="ti-pie-chart text-secondary fs-1"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h4 className="h5 mb-0">Practices For Industry</h4>
                    </div>
                  </div>
                </div>
                {/* Non utilisé */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ABOUT */}

      {/* COUNT */}
      <section class="bg-light">
        <div class="container">
          <div class="row align-items-center mt-n2-2">
            <div class="col-lg-6 order-2 order-lg-1 mt-2-2">
              <div class="pe-xxl-6">
                <div class="section-heading6">
                  <h2 class="text-primary text-start w-100">
                    Destinataires &{" "}
                    <span class="text-secondary">objectifs</span>
                  </h2>
                </div>
                <p class="mb-2-9 lead">
                  L'Institut propose des études systématiques en sciences
                  humaines et théologiques à tous les fidèles laïcs, religieuses
                  et religieux, dans le but d'approfondir la foi et de fournir
                  les connaissances nécessaires pour communiquer la foi aux
                  autres, comme catéchistes ou animateurs de groupe.
                </p>
                <div class="row mt-n4">
                  <div class="col-sm-6 text-center text-sm-start mt-4">
                    <div class="counter-icon-box d-sm-flex align-items-center box-shadows p-3 p-xl-4 w-100 h-100">
                      <div class="flex-shrink-0 mb-3 mb-sm-0">
                        <img src={Media.icon01} alt="..." />
                      </div>
                      <div class="flex-grow-1 border-sm-start border-color-extra-light-gray ps-sm-3 ps-xl-4 ms-sm-3 ms-xl-4 counter-style3 w-100 h-100">
                        <h3 class="countup text-primary mb-1">1160</h3>
                        <span class="font-weight-500 text-muted">
                          Etudiants
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 text-center text-sm-start mt-4">
                    <div class="counter-icon-box d-sm-flex align-items-center box-shadows p-3 p-xl-4 w-100 h-100">
                      <div class="flex-shrink-0 mb-3 mb-sm-0">
                        <img src={Media.icon02} alt="..." />
                      </div>
                      <div class="flex-grow-1 border-sm-start border-color-extra-light-gray ps-sm-3 ps-xl-4 ms-sm-3 ms-xl-4 counter-style3">
                        <h3 class="countup text-primary mb-1">200</h3>
                        <span class="font-weight-500 text-muted">
                          Enseignant
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 text-center text-sm-start mt-4">
                    <div class="counter-icon-box d-sm-flex align-items-center box-shadows p-3 p-xl-4 w-100 h-100">
                      <div class="flex-shrink-0 mb-3 mb-sm-0">
                        <img src={Media.icon03} alt="..." />
                      </div>
                      <div class="flex-grow-1 border-sm-start border-color-extra-light-gray ps-sm-3 ps-xl-4 ms-sm-3 ms-xl-4 counter-style3">
                        <h3 class="countup text-primary mb-1">1000</h3>
                        <span class="font-weight-500 text-muted">
                          Diplômes
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 text-center text-sm-start mt-4">
                    <div class="counter-icon-box d-sm-flex align-items-center box-shadows p-3 p-xl-4 w-100 h-100">
                      <div class="flex-shrink-0 mb-3 mb-sm-0">
                        <img src={Media.icon04} alt="..." />
                      </div>
                      <div class="flex-grow-1 border-sm-start border-color-extra-light-gray ps-sm-3 ps-xl-4 ms-sm-3 ms-xl-4">
                        <h3 class="countup text-primary mb-1">5</h3>
                        <span class="font-weight-500 text-muted">
                          Succusales
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 order-1 order-lg-2 mt-2-2"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <div class="text-center text-lg-end">
                <img src={Media.service01} alt="..." />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* COUNT */}

      {/* TEAM /STAF */}
      <section class="bg-white d-none">
        <div class="container">
          <div class="section-heading5">
            <span class="sub-title">ADMINISTRATION</span>
            <h2>
              Découvrez notre <span class="text-secondary">Administration</span>
            </h2>
            <div class="divider"></div>
          </div>
          <div class="row mt-n1-9">
            <div class="col-sm-6 col-lg-4 mt-1-9">
              <div class="card card-style12">
                <div class="team-image">
                  <img src="/assets/img/team/team-08.jpg" alt="..." />
                  <ul class="team-social-icon">
                    <li>
                      <a href="#!">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#!">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#!">
                        <i class="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#!">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="card-body">
                  <a href="#">
                    <h4>Haile Biniam</h4>
                  </a>
                  <span class="font-weight-500 text-secondary">
                    CEO & Founder
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 mt-1-9">
              <div class="card card-style12">
                <div class="team-image">
                  <img src="/assets/img/team/team-09.jpg" alt="..." />
                  <ul class="team-social-icon">
                    <li>
                      <a href="#!">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#!">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#!">
                        <i class="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#!">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="card-body">
                  <a href="#">
                    <h4>Samira Elias</h4>
                  </a>
                  <span class="font-weight-500 text-secondary">
                    Senior Manager
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 mt-1-9">
              <div class="card card-style12">
                <div class="team-image">
                  <img src="/assets/img/team/team-10.jpg" alt="..." />
                  <ul class="team-social-icon">
                    <li>
                      <a href="#!">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#!">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#!">
                        <i class="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#!">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="card-body">
                  <a href="#">
                    <h4>Conall Kennedy</h4>
                  </a>
                  <span class="font-weight-500 text-secondary">
                    Design Expert
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* TEAM /STAF */}
      {/* FOOTER */}
      <Footer />
    </div>
  );
}
