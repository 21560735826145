import About from "./layouts/about/About";
import Blog from "./layouts/blog/Blog";
import BlogDetails from "./layouts/blog/Blog-details";
import Contacts from "./layouts/contacts/Contacts";
import { Home } from "./layouts/home/Home";
import Services from "./layouts/services/Services";

export const routes = [
  { path: "/", element: <Home /> },
  { path: "/*", element: <Home /> },
  { path: "/about", element: <About /> },
  { path: "/blog", element: <Blog /> },
  { path: "/informations", element: <BlogDetails /> },
  { path: "/services", element: <Services /> },
  { path: "/contacts", element: <Contacts /> },
];
