import React from "react";
import Header from "../../inc/Header";
import * as Media from "../../media/Images";
import Footer from "../../inc/Footer";
import { Infos } from "../../inc/Head";

export default function Blog() {
  return (
    <div className="main-wrapper">
      <Header children={<Infos />} />
      {/* TITLE */}
      <section
        className="page-title-section bg-img cover-background primary-overlay"
        data-overlay-dark="7"
        data-background={Media.banner01}
        style={{ background: `url(${Media.bg02})` }}
      >
        <div className="row position-relative z-index-1">
          <div className="col-md-12">
            <div className="faded-text">
              <div className="f-text">
                <span className="main-font">Activité</span>
              </div>
            </div>
            <h1>Activité & Informations</h1>
            <ul className="breadcrumb">
              <li>
                <a href="/">Accueil</a>
              </li>
              <li>
                <a href="/blog">Blog</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* TITLE */}

      {/* BLOG */}
      <section>
        <div className="container">
          <div className="section-heading5">
            <span className="sub-title">Activités & Informations</span>
            <h2>
              Vivez Nos Dernières{" "}
              <span className="text-secondary">Activités</span>
            </h2>
            <div className="divider"></div>
          </div>
          <div className="row mt-n1-9">
            <div className="col-md-6 col mt-1-9">
              <article className="card card-style7 h-100">
                <div className="image-box">
                  <iframe
                    style={{ width: "100%" }}
                    height="315"
                    src="https://www.youtube.com/embed/a9_EgafNN-c?si=BN0cWZi40RLU_XiZ"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                  <div className="date">
                    <a href="#!">Mai </a>
                    <a href="#!"> 24</a>
                  </div>
                </div>
                <div className="card-body">
                  <div className="category">
                    <span>
                      CONFERENCE-DEBAT : LA GESTION DU NOUVEAU CODE FONCIER ET
                      LA SECURISATION DES TERRES AU TOGO, CENTRE CHRIST
                      REDEMPTEUR ( BROTHERHOME ), LOME
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-grow-1">
                      <h5 className="mb-0 h6 text-primary">ISP TOGO</h5>
                    </div>
                  </div>
                  <h3 className="h4 mb-3 d-none">
                    <a href="/informations">Conférence inogurale</a>
                  </h3>
                  <p className="mb-3">
                    Méditons ensemble les textes liturgiques de l'Eglise avec
                    les Pères de la Société des Missions Africaines de la
                    Province du Togo. Prions les uns pour les autres.
                  </p>
                </div>
              </article>
            </div>

            <div className="col-md-6 col mt-1-9">
              <article className="card card-style7 h-100">
                <div className="image-box">
                  <iframe
                    style={{ width: "100%" }}
                    height="315"
                    src="https://www.youtube.com/embed/B19S31c9ztA?si=hk16R34lDU49_G6C"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>

                  <div className="date">
                    <a href="#!">Août </a>
                    <a href="#!"> 24</a>
                  </div>
                </div>
                <div className="card-body">
                  <div className="category">
                    <span>
                      CHANGEMENTS CLIMATIQUES ET RECHAUFFEMENT CLIMATIQUE-EFFETS
                      ET IMPACTS EN AFRIQUE ET AU TOGO
                    </span>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-grow-1">
                      <h5 className="mb-0 h6 text-primary">ISP TOGO</h5>
                    </div>
                  </div>
                  <h3 className="h4 mb-3 d-none">
                    <a href="/informations">Conférence inogurale</a>
                  </h3>
                  <p className="mb-3">
                    L'INSTITUT DE PEDAGOGIE RELIGIEUSE SAINT PAUL DE LOME EN
                    PARTENARIAT AVEC LA FONDATION HANNS SEIDEL ORGANISE UNE
                    CONFERENCE-DEBAT CONFERENCIER Prof.Dr. ISIDORE Kodjovi
                    EDJAME,Chercheur en Climatologie aux Universites du Togo.
                    Centre Christ Rédempteur ( BrotherHome),Lomé
                  </p>
                </div>
              </article>
            </div>
          </div>
          <div className="row d-none">
            <div className="col-sm-12">
              {/* <!-- start pager  --> */}
              <div className="text-center mt-6 mt-lg-7">
                <div className="pagination text-primary">
                  <ul>
                    <li>
                      <a href="#!">
                        <i className="fas fa-long-arrow-alt-left me-2"></i> Prev
                      </a>
                    </li>
                    <li className="active">
                      <a href="#!">1</a>
                    </li>
                    <li>
                      <a href="#!">2</a>
                    </li>
                    <li>
                      <a href="#!">3</a>
                    </li>
                    <li>
                      <a href="#!">
                        Next<i className="fas fa-long-arrow-alt-right ms-2"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- end pager --> */}
            </div>
          </div>
        </div>
      </section>
      {/* BLOG */}

      <Footer />
    </div>
  );
}
